@charset "utf-8";

/* default */
html {/*height:100%;*/}
body {overflow-x:hidden;/*height:100%;*/font-family:'Spoqa Han Sans Neo','Josefin Sans', 'Nanum Myeongjo', 'sans-serif';font-size:12px;font-weight:400;line-height:1.3;color:#4b4c4c;background-color:#fff;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none;-moz-osx-font-smoothing:gratranslate3d(0,0,0)yscale;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,form,fieldset,p,button,iframe,th,td{margin:0;padding:0;}
article,aside,details,figcaption,figure,footer,header,hgroup,nav,section,summary{display:block;margin:0;padding:0;}
h1,h2,h3,h4,h5,h6,dt{font-weight:normal;}
img,fieldset,iframe,button{border:0 none;}
button{cursor:pointer;background:none}
button:focus{outline:0;}
li{list-style:none;}
button{border:0 none}
table{ border-collapse:collapse;border-spacing:0; }
th, td{border-collapse:collapse;}
legend, caption, hr{display:none;}
select, input, textarea {}
i, em, u, cite {font-style:normal;}
strong{font-weight:normal;}
img{vertical-align:top;max-width:100%;}
a{color:#4b4c4c;text-decoration:none;outline-offset:-1px;transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s;}
table {table-layout:fixed;width:100%;}

/* common */
main {overflow-x:hidden;-webkit-overflow-scrolling:touch;}
/*section {max-width:1440px;margin:0 auto;}*/
#contents {position:relative;min-width:320px;margin:0 auto;min-height:100%;}
#contents a {transition:all .2s;-webkit-transition:all .2s;-ms-transition:all .2s;}
#contents a:hover,
#contents a:focus {opacity:.8;}

input[type="checkbox"],
input[type="radio"] {display:none;}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {-webkit-appearance:none;margin:0;}
.btn-menu {display:none;}
.mobile-nav {display:none;}
.mobile {display:none;}
.br {display:block;}
.text-r {text-align:right;}
.cont-box .cont {display:none;}
.cont-box .cont.on {display:block;}

.select-box {position:relative;margin-left:40px;display:inline-block;vertical-align:middle;font-family:'Josefin Sans';}
.select-box button {position:relative;padding-right:28px;font-size:12px;line-height:56px;font-weight:bold;letter-spacing:1px;color:#fff;text-transform:uppercase;}
.select-box button:after {content:'';position:absolute;z-index:2;top:50%;right:0;margin-top:-12px;width:24px;height:24px;background:url('./assets/images/ico_dropdown.png') 0 0 no-repeat;background-size:auto 24px;transition:transform .2s;-webkit-transition:transform .2s;-ms-transition:transform .2s;}
.select-box button.on:after {transform:rotate(180deg);-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);}
.select-box > div {display:none;overflow:hidden;padding:4px 0;z-index:1;position:absolute;width:86px;top:-3px;left:32px;text-align:center;background:#fff;border-radius:20px;border:solid 1px rgba(167, 176, 180, 0.5);box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.1);-webkit-box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.1);-ms-box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.1);text-align:left;}
.select-box > div ul li:first-child {border-top:0 none;}
.select-box > div ul li {border-top: 1px solid #eaeaea;}
.select-box > div ul li a {display:block;padding:0 38px 0 16px;font-size:16px;line-height:44px;letter-spacing:-0.58px;color:#0a141c;text-transform:uppercase;}
.select-box > div ul li a:hover {background:#f8f7fd;}

.connected {position:relative;display:inline-block;vertical-align:middle;margin-left:48px;}
.connected .nick-wrap {display:flex;align-items:center;}
.connected .nick-thumb {width:38px;height:38px;margin-right:16px;border:solid 1px #474d46;background-image:linear-gradient(to bottom, #f2f3f2, #9eac9e);border-radius:100%;font-family:SDGyeokdongG207Bk;font-size:14px;font-weight:bold;line-height:48px;color:#0a141c;text-align:center;}
.connected .nick-txt button {position:relative;padding-right:24px;font-family:'Josefin Sans';font-size:14px;color:#fff;}
.connected .nick-txt button:after {content:'';position:absolute;z-index:2;top:50%;right:0;margin-top:-10px;width:20px;height:20px;background:url('./assets/images/ico_dropdown-sub.png') 0 0 no-repeat;background-size:auto 20px;transition:transform .2s;-webkit-transition:transform .2s;-ms-transition:transform .2s;}
.connected .nick-txt button.on:after {transform:rotate(180deg);-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);}
.connected .nick-txt span {display:block;margin-top:4px;font-family:'Josefin Sans';font-size:12px;color:#fff;}

.connect-select {display:none;overflow:hidden;padding:10px 0;z-index:1;position:absolute;top:60px;left:0;text-align:left;background:#7a928c;border-radius:16px;box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.3);-webkit-box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.3);-ms-box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.3);box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.connect-select ul li {position:relative;}
.connect-select ul li a {display:block;padding:0 16px 0 48px;font-family:'Poppins';font-size:14px;line-height:40px;font-weight:500;color:#fff;}
.connect-select ul li a:hover {background:#69827c;}
.connect-select ul li.ico-connect01 {background:url('./assets/images/ico_connect01.png') 16px 50% no-repeat;background-size:auto 20px;}
.connect-select ul li.ico-connect02 {background:url('./assets/images/ico_connect02.png') 16px 50% no-repeat;background-size:auto 20px;}
.connect-select ul li.ico-connect03 {background:url('./assets/images/ico_connect03.png') 16px 60% no-repeat;background-size:auto 20px;padding-top:6px;margin-top:6px;}
.connect-select ul li:last-child:after {content:'';position:absolute;top:0;left:50%;margin-left:-64px;width:128px;height:1px;background:rgba(255, 255, 255, .1);}

.network-error {z-index:9999;position:absolute;top:94px;right:10px;background:#bc6867 url('./assets/images/ico_error.png') no-repeat;border-radius:8px;padding:0 12px 0 46px;font-size:12px;font-weight:500;line-height:44px;color:#fff;font-family:'Spoqa Han Sans Neo';background-size:auto 24px;background-position:12px 10px;}

.btn-line {display:block;width:368px;border:2px solid #fff;background:rgba(224, 228, 224, 0.3);font-family:'Spoqa Han Sans Neo';font-size:14px;font-weight:bold;line-height:52px;letter-spacing:1.17px;color:#fff;text-align:center;}
.btn-line.green {border-color:#474d46;color:#474d46;}
.btn-round {display:block;border-radius:12px;background:#7a928c;font-family:'Spoqa Han Sans Neo';font-size:16px;font-weight:bold;line-height:56px;letter-spacing:1px;text-align:center;color:#fff;}
.btn-round.disabled {opacity:.3;}

.desc {font-family:'Spoqa Han Sans Neo';font-size:16px;line-height:1.63;color:#474d46;text-align:center;}

.tag {display:inline-block;padding:0 10px;border-radius:6px;font-family:'Josefin Sans';font-size:14px;font-weight:600;line-height:32px;color:#fff;}
.tag.orange {background:#e45f35;}
.tag.purple {background:#9b71dc;}
.tag.blue {background:#71b0dc;}
.tag.gray {background:#ababab;}
.tag.green {background:#7a928c;}

/* header */
header {position:absolute;top:0;left:0;width:100%;z-index:999;}
.header-inner {overflow:visible;max-width:1440px;margin:0 auto;padding:56px 64px;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.header-inner:after {display:block;clear:both;content:'';}
.header-inner h1 {float:left;text-indent:-9999px;background:url('./assets/images/logo_white.png') 50% no-repeat;background-size:auto 30px;width:160px;height:56px;}
.header-inner h1 a {display:block;height:100%;}
.header-inner h1 a:hover,
.header-inner h1 a:focus {opacity:.2;}
.header-inner > div {float:right;font-size:0;}
.header-inner nav,
.header-inner .btn-line {display:inline-block;vertical-align:middle;}
.header-inner .gnb {font-size:0;}
.header-inner .gnb li {display:inline-block;vertical-align:middle;margin-left:40px;padding:16px 0;}
.header-inner .gnb li:first-child {margin-left:0;}
.header-inner .gnb li a {position:relative;display:block;font-family:'Josefin Sans';font-size:12px;font-weight:600;line-height:24px;letter-spacing:3px;color:#fff;text-transform:uppercase;}
.header-inner .gnb li.curr a:after {content:'';position:absolute;bottom:-2px;left:50%;margin-left:-9px;width:18px;height:2px;background:#fff;}
.header-inner .gnb .curr a {font-weight:bold;}
.header-inner .btn-line {margin-left:40px;padding:0 28px;width:auto;font-family:'Josefin Sans';font-size:12px;font-weight:bold;line-height:52px;letter-spacing:1px;text-align:center;color:#fff;text-transform:uppercase;}

.white-ver {background:#fff;}
.white-ver .header-inner {padding:21px 64px 19px;}
.white-ver .header-inner h1 {background-image:url('./assets/images/logo_black.png');height:40px;}
.white-ver .header-inner .gnb li {padding:0;}
.white-ver .header-inner .gnb li a {color:#474d46;}
.white-ver .header-inner .gnb li.curr a:after {background:#7a928c;}
.white-ver .header-inner .btn-line {border-color:#474d46;color:#474d46;background-color:rgba(224, 228, 224, 0.1);}
.white-ver .connected .nick-txt button {color:#474d46;}
.white-ver .connected .nick-txt span {color:#7a928c;}
.white-ver .select-box button {color:#474d46;line-height:40px;}
.white-ver .select-box button:after {background-image:url('./assets/images/ico_dropdown-b.png');}

.tit {position:relative;padding-bottom:18px;font-family:'Spoqa Han Sans Neo';font-size:32px;font-weight:500;line-height:1.25;text-align:center;color:#474d46;}
.tit:after {content:'';position:absolute;bottom:0;left:50%;margin-left:-38px;width:76px;height:4px;background:#474d46;}
.tit.text-l {text-align:left;}
.tit.text-l:after {left:0;margin-left:0;}
.tit.white {color:#fff;}
.tit.white:after {background:#fff;}
.eng {font-family:'Josefin Sans';}

.flex {display:flex;align-items:center;justify-content:space-between;}


/* content */
.inner-box {position:relative;max-width:1440px;margin:0 auto;z-index:0;}
.kv {background:url('./assets/images/bg_kv.jpg') 0 50% no-repeat;height:956px;background-size:cover;}
.kv .text {max-width:1200px;margin:0 auto;padding:194px 0 90px;}
.kv .text span {display:block;font-family:'Spoqa Han Sans Neo';font-size:32px;line-height:1.25;color:#fff;}
.kv .text strong {display:block;margin-top:32px;font-family:'Josefin Sans';font-size:80px;font-weight:bold;line-height:1.25;letter-spacing:4.8px;color:#fff;}
.kv .text strong + p {margin-top:71px;}
.kv .text p {position:relative;width:450px;margin-top:32px;font-family:'Spoqa Han Sans Neo';font-size:16px;line-height:1.63;color:#fff;word-break:keep-all;}
.kv .text .line:after {content:'';position:absolute;top:-23px;left:1px;width:35px;height:3px;background:#fff;}

.feature01 {padding:160px 0;background:rgba(224, 228, 224, .45);}
.feature01 .illust {position:absolute;top:-109px;left:0;}
.feature01 .desc {margin-top:30px;}
.feature01 .product-wrap {margin-top:56px;}
.feature01 .product-wrap .bx-wrapper {width:1242px;margin:0 auto;box-shadow:none;border:none;background:none;}
.feature01 .product-wrap .bx-wrapper .bx-controls-direction a {top:174px;margin-top:0;width:24px;height:24px;}
.feature01 .product-wrap .bx-wrapper .bx-prev {left:-43px;background:url('./assets/images/bg_arrow-prev.png') no-repeat;}
.feature01 .product-wrap .bx-wrapper .bx-next {right:-43px;background:url('./assets/images/bg_arrow-next.png') no-repeat;}
.feature01 .product-wrap .bx-wrapper .bx-next:focus,
.feature01 .product-wrap .bx-wrapper .bx-next:hover {background-position:0;}
.feature01 .product-wrap ul {overflow:hidden;}
.feature01 .product-wrap ul:after {content:'';display:block;clear:both;}
.feature01 .product-wrap ul li {float:left;width:372px;margin:0 21px;}
.feature01 .product-wrap figure {display:table-cell;vertical-align:middle;width:372px;height:372px;border-radius:24px;box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-webkit-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-ms-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);background:#fbfbfb;}
.feature01 .product-wrap figure img {margin:0 auto;}
.feature01 .product-wrap .info {margin-top:24px;}
.feature01 .product-wrap .info strong {overflow:hidden;display:block;margin-top:16px;padding-bottom:20px;border-bottom:2px solid #e0e4e0;text-overflow:ellipsis;white-space:nowrap;width:372px;font-family:'Spoqa Han Sans Neo';font-size:24px;font-weight:500;line-height:1.08;color:#474d46;}
.feature01 .product-wrap .info .btn-round {margin-top:20px;opacity:.15;}
.feature01 .slider-m {display:none;}

.feature02 {background:#a0b7b1;}
.feature02 .flex {background:#a0b7b1;}
.feature02 .tit {color:#fff;}
.feature02 .tit:after {background:#fff;}
.feature02 .desc {color:#fff;}
.feature02 figure {flex:none;}
.feature02 .text {padding:0 142px;text-align:center;}
.feature02 .text p {margin-top:28px;}
.feature02 .text strong {display:block;margin-top:56px;font-family:'Spoqa Han Sans Neo';font-size:20px;font-weight:500;line-height:1.3;text-align:center;color:#fff;}
.feature02 .btn-line {margin:16px auto 0;font-family:'Josefin Sans';color:#fff;}

.feature03 {padding:160px 0;background:#e0e4e0;}
.feature03 strong {display:block;font-family:'Spoqa Han Sans Neo';font-size:40px;font-weight:500;line-height:1.4;text-align:center;color:#474d46;}
.feature03 .flex {align-items:flex-end;margin-top:100px;padding:0 160px 0 201px;}
.feature03 .flex figure {flex:none;margin-right:80px;}
.feature03 .text p {margin-top:28px;text-align:left;}

.feature04 {overflow:hidden;position:relative;padding:160px 0;background:#1e2121;text-align:center;}
.feature04 .membership {position:relative;margin-top:95px;font-size:0;}
.feature04 .membership > div {position:relative;display:inline-block;vertical-align:top;margin:0 36px;width:300px;box-shadow:0 20px 20px 0 rgb(71 77 70 / 10%);-webkit-box-shadow:0 20px 20px 0 rgb(71 77 70 / 10%);-ms-box-shadow:0 20px 20px 0 rgb(71 77 70 / 10%);background:#fbfbfb;border-radius:32px;}
.feature04 .membership ul {padding:168px 0 68px 72px;}
.feature04 .membership li {position:relative;margin-top:16px;padding-left:16px;font-family:'Spoqa Han Sans Neo';font-size:14px;font-weight:500;line-height:18px;color:#474d46;text-align:left;}
.feature04 .membership li:first-child {margin-top:0;}
.feature04 .membership li:after {content:'';position:absolute;left:0;top:50%;margin-top:-4px;width:8px;height:8px;border-radius:100%;}
.feature04 .membership .mem-tit {position:absolute;padding-top:24px;width:100%;height:136px;line-height:51px;top:2px;left:0;text-shadow:0 2px 5px rgba(0, 0, 0, 0.3);font-family:'Josefin Sans';font-size:24px;font-weight:500;text-align:center;color:#fff;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.feature04 .membership .membership01 .mem-tit {background:url('./assets/images/bg_benefit-tit01.png') no-repeat;}
.feature04 .membership .membership01 li:after {background:#7a928c;}
.feature04 .membership .membership02 .mem-tit {background:url('./assets/images/bg_benefit-tit02.png') no-repeat;}
.feature04 .membership .membership02 li:after {background:#877a92;}
.feature04 .membership .membership03 .mem-tit {background:url('./assets/images/bg_benefit-tit03.png') no-repeat;}
.feature04 .membership .membership03 li:after {background:#bfb79c;}


.feature04 > span {display:block;margin-top:32px;font-size:16px;line-height:2;text-align:center;color:#fff;}
.feature04 > p {margin-top:32px;font-family:'Spoqa Han Sans Neo';font-size:20px;font-weight:400;line-height:1.6;color:#fff;}
.feature04 > p i {font-weight:500;}
.feature04 > figure {overflow:hidden;width:420px;margin:56px auto 0;border-radius:40px;}
.feature04 > figure img {height:420px;}
.feature04 > figure video {width:100%;}
.feature04 .illust {position:absolute;top:160px;right:33px;}
.feature04 .btn-line {margin:72px auto 0;}

/* about */

.about-kv .text {position:absolute;top:280px;left:0;width:100%;font-family:'Spoqa Han Sans Neo';text-align:center;}
.about-kv .text span {display:block;font-size:32px;line-height:1.25;color:#fff;}
.about-kv .text strong {display:block;margin-top:32px;font-size:80px;font-weight:bold;line-height:1.25;color:#fff;}
.about-kv.kv01 {background:url('./assets/images/img_about.jpg') 0 50% no-repeat;height:956px;background-size:cover;}
.about-kv.kv02 {background:url('./assets/images/img_about-abg.jpg') 0 50% no-repeat;height:852px;background-size:cover;}
.about-kv.kv02 .text {top:208px;}
.about-kv.kv02 .text .tit {font-size:80px;font-weight:bold;line-height:1.25;letter-spacing:4.8px;color:#fff;}
.about-kv.kv02 .text .tit:after {margin-left:-40px;width:80px;height:8px;}
.about-kv.kv02 .text strong {margin-top:72px;font-size:32px;line-height:1.25;color:#fff;}
.about-kv.kv02 .text p {margin-top:32px;font-size:16px;line-height:1.63;color:#fff;}

.effect-box {z-index:1;height:330px;position:absolute;top:-148px;left:0;width:100%;text-align:center;}
.effect-box:before,
.effect-box:after {content:'';position:absolute;width:916px;left:50%;margin-left:-458px;z-index:-1;}
.effect-box:before {top:0;height:300px;border-radius:4px;box-shadow:0 2px 27px 0 rgba(0, 0, 0, 0.4);-webkit-box-shadow:0 2px 27px 0 rgba(0, 0, 0, 0.4);-ms-box-shadow:0 2px 27px 0 rgba(0, 0, 0, 0.4);background:#fbfbfb;}
.effect-box:after {background:linear-gradient(0deg, rgba(251,251,251,1) 46%, rgba(251,251,251,0) 100%);width:1168px;height:160px;margin-left:-584px;bottom:0;}

.about01 {position:relative;padding-bottom:160px;background:#fbfbfb;text-align:center;}
.about01 .tit {z-index:1;top:-36px;margin-bottom:-36px;}
.about01 .desc {position:relative;z-index:1;width:596px;margin:0 auto;}
.about01 .desc p {margin-top:28px;word-break:keep-all;}
.about01 .about01-1 figure {margin-top:32px;}
.about01 .about01-1 figcaption {margin-top:32px;font-family:'Nanum Myeongjo';font-size:18px;font-weight:800;line-height:1.78;color:#474d46;}
.about01 .about01-1 .desc p,
.about01 .about01-1 .desc strong {margin-top:32px;}
.about01 .about01-1 .desc strong {display:block;font-weight:bold;}
.about01 .about01-2 figure {margin-top:17px;}
.about01 .about01-2 .desc {margin-top:21px;}
.about01 .about01-2 .desc strong {font-weight:bold;}
.about01 .about01-2 .btn-line {margin:40px auto 0;}

.about02 {position:relative;padding-bottom:160px;background:#fbfbfb;text-align:center;}
.about02 .effect-box {height:345px;top:-106px;}
.about02 .effect-box:before,
.about02 .effect-box:after {width:1000px;margin-left:-500px;}
.about02 .effect-box:before {height:253px;}
.about02 .effect-box:after {height:240px;}
.about02 figure {position:relative;z-index:1;top:-92px;margin-bottom:-92px;}
.about02 .desc {width:480px;word-break:keep-all;margin:56px auto 0;}
.about02 .desc p {margin-top:28px;}
.about02 .btn-line {margin:40px auto 0;}

/* ground */
.ground-kv {background:url('./assets/images/img_ground.jpg') 0 50% no-repeat;height:956px;background-size:cover;}
.ground-kv .text {
    position:absolute;top:252px;right:115px;width:536px;padding:40px;
    font-family:'Spoqa Han Sans Neo';text-align:center;
    object-fit:contain;border-radius:40px;background:rgba(122, 146, 140, 0.24);
    -webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);
    box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;
    box-shadow:2px 2px 4px 0 rgba(0, 0, 0, 0.2);-webkit-box-shadow:2px 2px 4px 0 rgba(0, 0, 0, 0.2);-ms-box-shadow:2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.ground-kv .text strong {position:relative;display:block;padding-bottom:28px;font-size:32px;font-weight:500;line-height:1.25;letter-spacing:1px;color:#fff;}
.ground-kv .text strong:after {content:'';position:absolute;left:50%;margin-left:-25px;bottom:0;width:50px;height:4px;background:#fff;}
.ground-kv .text p {margin-top:24px;font-size:16px;line-height:1.63;color:#fff;}
.ground-kv .text span {display:block;margin-top:16px;font-size:20px;font-weight:500;line-height:1.3;color:#fff;}
.ground-kv .text .btn-line {width:170px;margin:32px auto 0;}

.ground01 {overflow:hidden;padding:160px;background:#e0e4e0;}
.ground01 .desc {margin-top:28px;}
.ground01 .ground-slide {margin-top:54px;position:relative;width:1300px;left:50%;margin-left:-650px;}
.ground01 .ground-slide li {overflow:hidden;margin:0 36px;border-radius:32px;opacity:.5;transition: all 300ms ease;}
.ground01 .ground-slide .slick-active {opacity:1;transform:scale(1.08);}

.ground02 {padding:122px 0;background:#1e2121;}
.ground02 .inner-box {max-width:1120px;margin:0 auto;justify-content:flex-start;}
.ground02 figure {flex:496px 0 0;margin-right:128px;}
.ground02 .desc {margin-top:28px;font-size:16px;line-height:1.63;color:#fff;text-align:left;width:380px;word-break:keep-all;}

.ground03 {padding:160px 0;background:#a0b7b1;}
.ground03 .inner-box {max-width:1120px;margin:0 auto;}
.ground03 figure {flex:0 0 591px;}
.ground03 .desc {margin-top:28px;font-size:16px;line-height:1.63;color:#fff;text-align:left;}

.ground04 {padding:100px 0;text-align:center;}
.ground04 strong {display:block;font-size:32px;font-weight:500;line-height:1.25;letter-spacing:1px;color:#474d46;}
.ground04 .btn-line {width:214px;margin:24px auto 0;}


/* membership */
.papa-membership .kv .text {padding:235px 0 118px;}
.papa-membership .kv .text p {width:400px;}
.papa-membership .kv figure {overflow:hidden;position:absolute;right:160px;top:236px;width:560px;border-radius:40px;box-shadow:0 2px 40px 0 rgb(0 0 0 / 50%);-webkit-box-shadow:0 2px 40px 0 rgb(0 0 0 / 50%);-ms-box-shadow:0 2px 40px 0 rgb(0 0 0 / 50%);}
.papa-membership .kv .text strong {margin-top:46px;font-size:72px;line-height:normal;letter-spacing:normal;}
.papa-membership .kv .text strong + p {margin-top:47px;}
.papa-membership .kv .count-box {
    margin-top:32px;width:400px;border:solid 2px #fbfbfb;background:rgba(224, 228, 224, 0.1);
    -webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);
    font-family:'Josefin Sans';font-size:32px;font-weight:bold;line-height:76px;letter-spacing:2px;text-align:center;color:#fff;
}
.papa-membership .kv .btn-box {margin-top:24px;font-size:0;}
.papa-membership .kv .btn-box a {display:inline-block;vertical-align:middle;margin-right:16px;}
.papa-membership .kv .btn-box img {height:40px;}

.papa-membership .membership01 {padding:120px 0 125px;background:#a0b7b1;}
.papa-membership .membership01 .inner-box {max-width:994px;margin:0 auto;padding-top:112px;background:url('./assets/images/bg_effect-story.png') no-repeat;}
.papa-membership .membership01 p {margin-top:32px;font-size:16px;line-height:1.63;color:#fff;}

.papa-membership .membership02 {padding:160px 0;background:#1e2121;}
.papa-membership .membership02 .slide-wrap {margin-top:64px;}
.papa-membership .membership02 .slide-m {display:none;}
.papa-membership .membership02 .bx-wrapper {width:1236px;margin:0 auto;box-shadow:none;border:none;background:none;}
.papa-membership .membership02 .bx-wrapper .bx-controls-direction a {top:174px;margin-top:0;width:24px;height:24px;}
.papa-membership .membership02 .bx-wrapper .bx-prev {left:-43px;background:url('./assets/images/bg_arrow-prev.png') no-repeat;}
.papa-membership .membership02 .bx-wrapper .bx-next {right:-43px;background:url('./assets/images/bg_arrow-next.png') no-repeat;}
.papa-membership .membership02 .bx-wrapper .bx-next:focus,
.papa-membership .membership02 .bx-wrapper .bx-next:hover {background-position:0;}
.papa-membership .membership02 ul {overflow:hidden;}
.papa-membership .membership02 ul:after {content:'';display:block;clear:both;}
.papa-membership .membership02 ul li {float:left;width:372px;margin:0 20px;}

.papa-membership .membership03 {padding:160px 0;background:rgba(224, 228, 224, .45);}
.papa-membership .membership03 .bx-wrapper {margin:0;box-shadow:none;border:none;}
.papa-membership .membership03 .bx-wrapper .bx-pager {display:none;}
.papa-membership .membership03 > strong {display:block;margin-top:64px;font-family:'Josefin Sans';font-size:24px;font-weight:500;line-height:1.67;text-align:center;color:#474d46;}
.papa-membership .membership03 .desc {margin-top:24px;}
.papa-membership .membership03 .type-list {width:1248px;margin:16px auto 0;}
.papa-membership .membership03 .type-list > li {position:relative;overflow:hidden;display:inline-block;vertical-align:top;width:372px;min-height:730px;margin:48px 21px 0;border-radius:24px;box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-webkit-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-ms-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);border:solid 1px #e0e4e0;background:#fbfbfb;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.papa-membership .membership03 .type-list figure {overflow:hidden;position:relative;height:318px;}
.papa-membership .membership03 .type-list figure img {position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
.papa-membership .membership03 .type-list .tag {display:inline-block;position:absolute;top:20px;left:16px;border-radius:6px;padding:0 10px;font-family:'Josefin Sans';font-size:14px;font-weight:600;line-height:32px;color:#fff;}
.papa-membership .membership03 .type-list .tag.color-b {color:#000;}
.papa-membership .membership03 .type-list .text {padding:24px 20px 20px;}
.papa-membership .membership03 .type-list .text .list-tit {display:block;font-family:'Josefin Sans';font-size:20px;font-weight:bold;line-height:1.3;color:#474d46;}
.papa-membership .membership03 .type-list .text .flex {margin-top:16px;font-family:'Josefin Sans';}
.papa-membership .membership03 .type-list .text .flex span {font-size:12px;font-weight:500;line-height:26px;color:#7a928c;}
.papa-membership .membership03 .type-list .text .flex strong {font-size:18px;font-weight:300;line-height:26px;color:#474d46;}
.papa-membership .membership03 .type-list .text .flex strong em {font-weight:500;}
.papa-membership .membership03 .type-list .text .desc {margin-top:16px;padding:16px 0;border-top:1px solid #e0e4e0;border-bottom:1px solid #e0e4e0;font-size:14px;line-height:1.71;color:#474d46;text-align:left;word-break:keep-all;}
.papa-membership .membership03 .type-list .text .alert {margin-top:14px;font-size:14px;font-weight:bold;line-height:2;color:#474d46;}
.papa-membership .membership03 .type-list .text ul {margin-top:16px;}
.papa-membership .membership03 .type-list .text li {display:flex;justify-content:space-between;align-items:center;}
.papa-membership .membership03 .type-list .text li span {font-size:12px;font-weight:500;line-height:2.33;color:#7a928c;}
.papa-membership .membership03 .type-list .text li strong {font-size:14px;font-weight:bold;line-height:2;color:#474d46;}
.papa-membership .membership03 .btn-purchase {position:absolute;left:20px;bottom:20px;width:calc(100% - 40px);border-radius:12px;background:#7a928c;font-size:16px;font-weight:bold;line-height:56px;letter-spacing:1px;text-align:center;color:#fff;}
.bg-rainbow {background-image:linear-gradient(104deg, #ff6b6b 5%, #ffc877 24%, #fff58a 40%, #acff8e 61%, #95c5ff 79%, #9081ff 99%);}
.bg-black {background:#231f28;}
.bg-yellow {background:#f4ff00;}
.bg-orange {background:#ffda24;}
.bg-white {background:#fbfbfb;}
.bg-deep-red {background:#e8805f;}
.bg-green {background:#548c7e;}
.bg-purple {background:#9c7ccc;}

.papa-membership .membership04 {position:relative;padding:160px 0;background:#e0e4e0;}
.papa-membership .membership04 .illust {position:absolute;bottom:0;right:0;}
.papa-membership .membership04 .desc p {margin-top:32px;}
.papa-membership .membership04 .desc p strong {font-weight:bold;}
.papa-membership .membership04 .benefit {position:relative;margin-top:65px;font-size:0;text-align:center;}
.papa-membership .membership04 .benefit > div {position:relative;display:inline-block;vertical-align:middle;width:300px;height:400px;margin:0 36px;background:#fbfbfb;border-radius:32px;}

.papa-membership .membership04 .benefit li {margin-top:8px;font-family:'Spoqa Han Sans Neo';font-size:14px;font-weight:500;line-height:2.14;color:#474d46;}
.papa-membership .membership04 .benefit li:first-child {margin-top:0;}
.papa-membership .membership04 .benefit li span {position:relative;padding-left:16px;}
.papa-membership .membership04 .benefit li span:after {content:'';position:absolute;left:0;top:50%;margin-top:-4px;width:8px;height:8px;border-radius:100%;}
.papa-membership .membership04 .benefit .mem-tit {position:absolute;padding-top:24px;width:100%;height:136px;line-height:51px;top:2px;left:0;text-shadow:0 2px 5px rgba(0, 0, 0, 0.3);font-family:'Josefin Sans';font-size:24px;font-weight:500;text-align:center;color:#fff;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.papa-membership .membership04 .benefit .benefit01 .mem-tit {background:url('./assets/images/bg_benefit-tit01.png') no-repeat;}
.papa-membership .membership04 .benefit .benefit01 ul {padding-top:170px;}
.papa-membership .membership04 .benefit .benefit01 li span:after {background:#7a928c;}
.papa-membership .membership04 .benefit .benefit02 ul {padding-top:138px;}
.papa-membership .membership04 .benefit .benefit02 .mem-tit {background:url('./assets/images/bg_benefit-tit02.png') no-repeat;}
.papa-membership .membership04 .benefit .benefit02 li span:after {background:#877a92;}
.papa-membership .membership04 .benefit .benefit03 ul {padding-top:156px;}
.papa-membership .membership04 .benefit .benefit03 .mem-tit {background:url('./assets/images/bg_benefit-tit03.png') no-repeat;}
.papa-membership .membership04 .benefit .benefit03 li span:after {background:#bfb79c;}


.papa-membership .membership06 {padding:160px 0;background:#e0e4e0;}
.papa-membership .membership06 .qna-list {width:904px;margin:27px auto 0;}
.papa-membership .membership06 .qna-list .question a {display:block;font-size:18px;line-height:108px;color:#474d46;}
.papa-membership .membership06 .qna-list .question a:after {content:'';position:absolute;top:40px;right:0;width:30px;height:30px;background:url('./assets/images/ico_faq-unfold.png') 0 50% no-repeat;background-size:auto 30px;}
.papa-membership .membership06 .qna-list .question a em {margin-right:44px;font-family:'Josefin Sans';font-size:32px;color:#474d46;}
.papa-membership .membership06 .qna-list .answer {display:none;padding:4px 74px 60px 82px;font-size:14px;line-height:1.71;color:#7a928c;}
.papa-membership .membership06 .qna-list li {position:relative;}
.papa-membership .membership06 .qna-list li.on .answer {display:block;}
.papa-membership .membership06 .qna-list li.on .question a:after {background-image:url('./assets/images/ico_faq-fold.png');}
.papa-membership .membership06 .qna-list li {border-bottom:1px solid rgba(122, 146, 140, .3);}


/* shop */
.papa-shop {min-height:768px !important;padding-top:80px;background:rgba(224, 228, 224, .45);}
.shop-cont {max-width:1200px;margin:0 auto;padding-top:80px;padding-bottom:120px;}
.shop-cont .user-wrap {position:relative;}
.shop-cont .user-info {display:flex;}
.shop-cont .user-info .pic {width:54px;height:54px;margin-right:20px;border-radius:100%;border:1px solid #474d46;background-image:linear-gradient(to bottom, #f2f3f2, #9eac9e);}
.shop-cont .user-info .text {padding-top:3px;}
.shop-cont .user-info .text > strong {display:block;font-size:24px;color:#474d46;}
.shop-cont .user-info .text .btn-edit {display:inline-block;vertical-align:top;margin-top:3px;margin-left:8px;border-radius:6px;background:#474d46;padding:0 8px;font-family:'Josefin Sans';font-size:12px;font-weight:500;line-height:24px;text-align:center;color:#fff;}
.shop-cont .user-info .text .btn-edit i {padding-left:18px;background:url('./assets/images/bg_user-edit.png') no-repeat;background-size:auto 14px;}
.shop-cont .user-info .text > span {overflow:hidden;display:block;margin-top:4px;text-overflow:ellipsis;width:calc(100% - 80px);white-space:normal;font-size:12px;color:#7a928c;}
.shop-cont .user-info .btn {margin-top:18px;font-size:0;}
.shop-cont .user-info .btn a {display:inline-block;vertical-align:middle;margin-right:8px;padding:0 16px;border-radius:12px;background:#7a928c;font-size:14px;line-height:44px;font-weight:500;text-align:center;color:#fff;}
.shop-cont .user-info .btn span {padding-left:26px;}
.shop-cont .user-info .btn .btn-link span {background:url('./assets/images/bg_user-link.png') no-repeat;background-size:auto 16px;}
.shop-cont .user-info .btn .btn-share span {background:url('./assets/images/bg_user-share.png') no-repeat;background-size:auto 16px;}
.shop-cont .user-info.edit .edit-box {font-size:0;}
.shop-cont .user-info.edit .edit-box .input {display:inline-block;vertical-align:middle;width:253px;border-bottom:1px solid #474d46;}
.shop-cont .user-info.edit .edit-box .input input {padding:0;background:none;border:none;width:100%;line-height:30px;font-family:'Spoqa Han Sans Neo';font-size:24px;color:#474d46;}
.shop-cont .user-info.edit .edit-box .btn-small {display:inline-block;vertical-align:middle;margin-left:4px;font-size:0;}
.shop-cont .user-info.edit .edit-box .btn-round {display:inline-block;vertical-align:middle;margin-left:6px;padding:0 8px;font-size:12px;line-height:24px;font-weight:500;text-align:center;border-radius:6px;}
.shop-cont .user-info.edit .edit-box .btn-round.full {background:#474d46;}
.shop-cont .user-info.edit .edit-box .btn-round.line {background:transparent;border:1px solid #474d46;line-height:22px;color:#474d46;}
.shop-cont .user-info.edit .edit-box .btn-round.disabled {opacity:.3;}
.shop-cont .user-info.edit .text .notice {margin-top:4px;font-size:12px;color:#e45f35;}
.shop-cont .user-info.edit .text > span {margin-top:2px;}
.shop-cont .user-info.edit .text .btn {margin-top:3px;}
.shop-cont .notice-box {position:absolute;top:0;right:0;background:#fff;border-radius:12px;padding:14px 14px 11px 16px;}
.shop-cont .notice-box strong {display:block;font-family:'Josefin Sans';font-size:24px;color:#474d46;}
.shop-cont .notice-box p {margin-top:5px;font-size:12px;color:#7a928c;}

.shop-cont .back {position:absolute;padding-left:48px;font-size:14px;line-height:24px;color:#474d46;background:url('./assets/images/btn_back.png') no-repeat;background-size:auto 24px;}
.shop-cont .back strong {display:block;font-size:24px;color:#474d46;}
.shop-cont .back p {margin-top:4px;font-size:12px;color:#7a928c;line-height:normal;}
.shop-cont .small-tit {padding-bottom:8px;font-size:14px;font-weight:bold;color:#474d46;border-bottom:1px solid #7a928c;}

.shop-cont .tab {margin-top:57px;border-bottom:1px solid rgba(122, 146, 140, .2);}
.shop-cont .tab li {position:relative;display:inline-block;vertical-align:middle;padding:7px 0;margin-left:16px;}
.shop-cont .tab li:first-child {margin-left:0;}
.shop-cont .tab li a {font-family:'Josefin Sans';font-size:14px;text-align:center;color:#7a928c;}
.shop-cont .tab .curr a {font-weight:bold;color:#474d46;}
.shop-cont .tab .curr:after {content:'';position:absolute;bottom:-1px;left:0;width:100%;height:1px;background:#474d46;}
.shop-cont .tab img {height:14px;}

.shop-cont .cont-box.empty {padding-top:128px;text-align:center;}
.shop-cont .cont-box.empty .ico-empty {display:block;padding-top:98px;background:url('./assets/images/bg_rectangle.png') 50% 0 no-repeat;font-size:20px;font-weight:500;color:#474d46;}
.shop-cont .cont-box.empty p {margin-top:10px;font-size:12px;color:#7a928c;}
.shop-cont .cont-box.empty .btn-box {margin-top:32px;}
.shop-cont .cont-box.empty .btn-box a {width:212px;margin:10px auto 0;}
.shop-cont .cont-box .desc {margin-top:16px;font-size:12px;color:#7a928c;line-height:normal;text-align:left;}

.shop-cont .btn-detail {display:block;margin-top:20px;border-radius:12px;background:#7a928c;font-size:16px;font-weight:bold;line-height:56px;letter-spacing:1px;text-align:center;color:#fff;}
.shop-cont .btn-product-info {display:block;margin-top:19px;font-size:16px;font-weight:600;line-height:20px;color:#7a928c;text-align:center;}
.shop-cont .btn-product-info span {padding-left:24px;background:url('./assets/images/ico_info.png') no-repeat;background-size:auto 20px;}
.shop-cont .btn-round.small {display:inline-block;vertical-align:middle;padding:0 10px;border-radius:6px;font-size:14px;line-height:32px;font-weight:normal;letter-spacing:initial;}

.shop-cont .membership-list {font-size:0;}
.shop-cont .membership-list > li {position:relative;overflow:hidden;display:inline-block;vertical-align:top;width:282px;margin:48px 0 0 24px;border-radius:24px;box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-webkit-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-ms-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);border:solid 1px #e0e4e0;background:#fbfbfb;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.shop-cont .membership-list > li:nth-child(4n+1) {margin-left:0;}
.shop-cont .membership-list > li:nth-child(-n+4) {margin-top:16px;}
.shop-cont .membership-list figure {position:relative;}
.shop-cont .membership-list .tag {display:inline-block;position:absolute;top:20px;left:16px;border-radius:6px;padding:0 10px;font-family:'Josefin Sans';font-size:14px;font-weight:600;line-height:32px;color:#fff;}
.shop-cont .membership-list .tag.color-b {color:#000;}
.shop-cont .membership-list .text {padding:22px 20px 24px;}
.shop-cont .membership-list .text .list-tit {display:block;font-family:'Josefin Sans';font-size:20px;font-weight:bold;line-height:1.3;color:#474d46;}

.shop-cont .nft-list {font-size:0;}
.shop-cont .nft-list > li {position:relative;overflow:hidden;display:inline-block;vertical-align:top;width:372px;margin:48px 0 0 42px;border-radius:24px;box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-webkit-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-ms-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);border:solid 1px #e0e4e0;background:#fbfbfb;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.shop-cont .nft-list > li:nth-child(3n+1) {margin-left:0;}
.shop-cont .nft-list > li:nth-child(-n+3) {margin-top:64px;}
.shop-cont .nft-list figure {position:relative;}
.shop-cont .nft-list .tag {display:inline-block;position:absolute;top:20px;left:16px;border-radius:6px;padding:0 10px;font-family:'Josefin Sans';font-size:14px;font-weight:600;line-height:32px;color:#fff;}
.shop-cont .nft-list .text {position:relative;padding:20px 20px 19px;}
.shop-cont .nft-list .text .list-tit {display:block;margin-top:10px;font-family:'Josefin Sans';font-size:20px;font-weight:bold;line-height:1.3;color:#474d46;}
.shop-cont .nft-list .text > em {display:block;font-family:'Josefin Sans';font-size:16px;font-weight:500;line-height:1.63;color:#7a928c;}
.shop-cont .nft-list .text .btn-box {margin-top:16px;padding:6px 0;border-top:1px solid #e0e4e0;border-bottom:1px solid #e0e4e0;}
.shop-cont .nft-list .text .btn-box a {margin:10px 0;}
.shop-cont .nft-list .text .btn-round.line {border:1px solid #7a928c;background:#fff;line-height:54px;color:#7a928c;}

.shop-cont .history-table {position:relative;margin-top:40px;border:1px solid rgba(122, 146, 140, .2);}
.shop-cont .history-table:after {content:'';position:absolute;top:-2px;left:-5px;width:160px;height:42px;background:#f1f4f1;}
.shop-cont .history-table .ko {font-family:'Spoqa Han Sans Neo';}
.shop-cont .history-table .empty {background:transparent;border-right:1px solid rgba(122, 146, 140, .2);}
.shop-cont .history-table th {background:rgba(224, 228, 224, 0.4);font-size:14px;font-weight:500;color:#474d46;font-family:'Josefin Sans';}
.shop-cont .history-table thead th {line-height:40px;border-bottom:1px solid rgba(122, 146, 140, .2);}
.shop-cont .history-table tbody th {line-height:40px;border-top:1px solid rgba(122, 146, 140, .2);border-right:1px solid rgba(122, 146, 140, .2);}
.shop-cont .history-table td {padding:10px 0;background:#fbfbfb;text-align:center;font-size:14px;font-weight:500;line-height:2;color:#474d46;}
.shop-cont .history-table .item {}
.shop-cont .history-table .item span,
.shop-cont .history-table .item p {display:inline-block;vertical-align:middle;}
.shop-cont .history-table .item span {margin-right:32px;}
.shop-cont .history-table .item span img {height:40px;}

.shop-cont .membership-list-wrap {position:relative;margin-top:9px;}
.shop-cont .membership-list-wrap .bx-wrapper {box-shadow:none;border:none;background:none;margin:0;}
.shop-cont .membership-list-wrap .slide-wrap {width:372px;margin:0 auto;}
.shop-cont .membership-list-wrap .slide-wrap .bx-wrapper .bx-controls-direction a {top:283px;margin-top:0;width:24px;height:24px;}
.shop-cont .membership-list-wrap .slide-wrap .bx-wrapper .bx-prev {left:-80px;background:url('./assets/images/bg_arrow-prev.png') no-repeat;}
.shop-cont .membership-list-wrap .slide-wrap .bx-wrapper .bx-next {right:-80px;background:url('./assets/images/bg_arrow-next.png') no-repeat;}
.shop-cont .membership-list-wrap .slide-wrap .bx-wrapper .bx-next:focus,
.shop-cont .membership-list-wrap .slide-wrap .bx-wrapper .bx-next:hover {background-position:0;}
.shop-cont .membership-list-wrap .type-list > li {position:relative;overflow:hidden;display:inline-block;vertical-align:top;width:372px;border-radius:24px;box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-webkit-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-ms-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);border:solid 1px #e0e4e0;background:#fbfbfb;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.shop-cont .membership-list-wrap .type-list figure {position:relative;}
.shop-cont .membership-list-wrap .type-list .tag {display:inline-block;position:absolute;top:20px;left:16px;border-radius:6px;padding:0 10px;font-family:'Josefin Sans';font-size:14px;font-weight:600;line-height:32px;color:#fff;}
.shop-cont .membership-list-wrap .type-list .tag.color-b {color:#000;}
.shop-cont .membership-list-wrap .type-list .text {padding:24px 20px 20px;}
.shop-cont .membership-list-wrap .type-list .text .list-tit {display:block;font-family:'Josefin Sans';font-size:20px;font-weight:bold;line-height:1.3;color:#474d46;}
.shop-cont .membership-list-wrap .type-list .text .flex {margin-top:16px;font-family:'Josefin Sans';}
.shop-cont .membership-list-wrap .type-list .text .flex span {font-size:12px;font-weight:500;line-height:26px;color:#7a928c;}
.shop-cont .membership-list-wrap .type-list .text .flex strong {font-size:18px;font-weight:300;line-height:26px;color:#474d46;}
.shop-cont .membership-list-wrap .type-list .text .flex strong em {font-weight:500;}
.shop-cont .membership-list-wrap .type-list .text .desc {margin-top:16px;padding:16px 0;border-top:1px solid #e0e4e0;border-bottom:1px solid #e0e4e0;font-size:14px;line-height:1.71;color:#474d46;text-align:left;word-break:keep-all;}
.shop-cont .membership-list-wrap .type-list .text .alert {margin-top:14px;font-size:14px;font-weight:bold;line-height:2;color:#474d46;}
.shop-cont .membership-list-wrap .type-list .text ul {margin-top:16px;}
.shop-cont .membership-list-wrap .type-list .text li {display:flex;justify-content:space-between;align-items:center;}
.shop-cont .membership-list-wrap .type-list .text li span {font-size:12px;font-weight:500;line-height:2.33;color:#7a928c;}
.shop-cont .membership-list-wrap .type-list .text li strong {font-size:14px;font-weight:bold;line-height:2;color:#474d46;}
.shop-cont .membership-list-wrap .usage-history {margin-top:40px;}
.shop-cont .membership-list-wrap .usage-history .desc {margin-top:20px;font-size:16px;color:#474d46;text-align:left;}
.shop-cont .membership-list-wrap .usage-history .btn-round {margin-top:20px;width:220px;}
.shop-cont .membership-list-wrap .tab {margin-top:64px;}
.shop-cont .membership-list-wrap .cont ul {font-size:0;}
.shop-cont .membership-list-wrap .cont li {display:inline-block;vertical-align:middle;margin:24px 0 0 24px;padding:20px;width:240px;border-radius:32px;box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-webkit-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);-ms-box-shadow:0 20px 50px 0 rgba(71, 77, 70, 0.05);border:solid 1px #e0e4e0;background:#fbfbfb;}
.shop-cont .membership-list-wrap .cont li:nth-child(4n+1) {margin-left:0;}
.shop-cont .membership-list-wrap .cont .flex {justify-content:flex-start;}
.shop-cont .membership-list-wrap .cont .flex.up {align-items:flex-start;}
.shop-cont .membership-list-wrap .cont .text strong {display:block;font-size:14px;font-weight:500;line-height:26px;color:#474d46;}
.shop-cont .membership-list-wrap .cont .text em {margin-top:10px;font-size:20px;font-weight:bold;color:#474d46;}
.shop-cont .membership-list-wrap .cont .text .num {margin-top:8px;font-family:'Josefin Sans';font-size:40px;line-height:37px;font-weight:500;}
.shop-cont .membership-list-wrap .cont .icon {margin-right:24px;}
.shop-cont .membership-list-wrap .cont .icon img {height:64px;}
.shop-cont .membership-list-wrap .cont .btn-mem {display:block;margin-top:16px;border-radius:10px;font-size:14px;line-height:40px;font-weight:500;text-align:center;}
.shop-cont .membership-list-wrap .cont .btn-mem.type01 {background:rgba(122, 146, 140, 0.1);color:#7a928c;}
.shop-cont .membership-list-wrap .cont .btn-mem.type02 {background:rgba(98, 148, 255, 0.1);color:#6294ff;}
.shop-cont .membership-list-wrap .cont .btn-mem.type03 {background:#7a928c;color:#fff;}
.shop-cont .membership-list-wrap .cont .num.add {padding-left:20px;background:url('./assets/images/bg_shop-pg-plus.png') 0 50% no-repeat;background-size:auto 16px;}
.shop-cont .membership-list-wrap .cont .num.up {padding-right:20px;background:url('./assets/images/bg_shop-pg-up.png') 100% 50% no-repeat;background-size:auto 16px;}
.shop-cont .membership-list-wrap .cont .desc {margin-top:19px;font-size:14px;color:#474d46;}
.shop-cont .membership-list-wrap .cont .btn {margin-top:12px;font-size:0;}
.shop-cont .membership-list-wrap .cont .btn a {display:inline-block;vertical-align:middle;margin-right:12px;padding:0 10px;border-radius:6px;background:#7a928c;font-family:'Josefin Sans';font-size:14px;font-weight:600;line-height:32px;color:#fff;}
.shop-cont .membership-list-wrap .cont .btn .disabled {opacity:.3;}

.shop-cont .nft-detail-wrap {margin-top:3px;}
.shop-cont .nft-detail-wrap .notice {position:absolute;font-size:12px;color:#e45f35;}
.shop-cont .nft-detail-wrap .exchange {padding-top:108px;}
.shop-cont .nft-detail-wrap .exchange .desc {margin-top:68px;font-weight:500;}
.shop-cont .nft-detail-wrap .exchange > .btn-round {width:220px;margin:24px auto 0;}
.shop-cont .nft-detail-wrap .exchange-box {margin-top:64px;}
.shop-cont .nft-detail-wrap .exchange-box .cont {position:relative;margin-top:24px;border:solid 1px #e0e4e0;background:#fbfbfb;}
.shop-cont .nft-detail-wrap .exchange-box > .btn-round.small {margin-top:8px;}
.shop-cont .nft-detail-wrap .exchange-box.product .flex {width: calc(100% - 316px);padding:24px 26px;justify-content:flex-start;border-right:1px solid rgba(122, 146, 140, .2);box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.shop-cont .nft-detail-wrap .exchange-box.product figure {flex:200px 0 0;margin-right:48px;}
.shop-cont .nft-detail-wrap .exchange-box.product img {height:200px;}
.shop-cont .nft-detail-wrap .exchange-box.product .btn-round {position:absolute;right:48px;top:50%;margin-top:-28px;width:220px;}
.shop-cont .nft-detail-wrap .exchange-box.product .info li {position:relative;margin-top:12px;}
.shop-cont .nft-detail-wrap .exchange-box.product .info li:first-child {margin-top:0;}
.shop-cont .nft-detail-wrap .exchange-box.product .info strong {display:inline-block;vertical-align:middle;width:190px;font-size:14px;font-weight:500;line-height:32px;color:#7a928c;}
.shop-cont .nft-detail-wrap .exchange-box.product .info div {display:inline-block;vertical-align:middle;font-size:14px;font-weight:bold;line-height:32px;color:#474d46;}
.shop-cont .nft-detail-wrap .exchange-box.product .info .tag {color:#fff;line-height:32px;}
.shop-cont .nft-detail-wrap .exchange-box.product .notice {left:193px;top:55px;width:100%;}

.shop-cont .nft-detail-wrap .quantity {position:relative;width:74px;padding-right:25px;border:solid 2px #e0e4e0;border-right:none;background:rgba(255, 255, 255, 0.5);line-height:44px !important;text-align:center;}
.shop-cont .nft-detail-wrap .quantity a {position:absolute;right:0;width:25px;height:25px;}
.shop-cont .nft-detail-wrap .quantity .btn-up {top:-2px;background:url('./assets/images/bg_shop-up.png') no-repeat;background-size:auto 25px;}
.shop-cont .nft-detail-wrap .quantity .btn-down {bottom:-2px;background:url('./assets/images/bg_shop-down-deactive.png') no-repeat;background-size:auto 25px;}
.shop-cont .nft-detail-wrap .quantity .btn-down.on {background-image:url('./assets/images/bg_shop-down.png');}

.shop-cont .nft-detail-wrap .btn-round + .cont {margin-top:8px;}
.shop-cont .nft-detail-wrap .cont-table th, td {border-top:1px solid rgba(122, 146, 140, .2);padding:14px 24px;line-height:32px;}
.shop-cont .nft-detail-wrap .cont-table tr:first-child th, tr:first-child td {border-top:none;}
.shop-cont .nft-detail-wrap .cont-table th {background:rgba(224, 228, 224, 0.4);font-size:14px;font-weight:500;text-align:right;color:#474d46;}
.shop-cont .nft-detail-wrap .cont-table td {position:relative;font-size:14px;font-weight:bold;color:#474d46;}
.shop-cont .nft-detail-wrap .cont-table input {width:100%;background:none;border:none;padding:0;line-height:32px;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.shop-cont .nft-detail-wrap .cont-table .notice {display:inline-block;vertical-align:middle;margin-left:12px;font-weight:normal;}
.shop-cont .nft-detail-wrap .cont-table .request {display:block;margin:14px 0;}
.shop-cont .nft-detail-wrap .cont-table .cert {font-size:0;}
.shop-cont .nft-detail-wrap .cont-table .cert-num {margin-top:8px;}
.shop-cont .nft-detail-wrap .cont-table .cert-num .phone {width:80px;}
.shop-cont .nft-detail-wrap .cont-table .phone {display:inline-block;vertical-align:middle;width:160px;margin-right:8px;border:solid 2px #e0e4e0;background:rgba(255, 255, 255, 0.5);box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;line-height:normal;}
.shop-cont .nft-detail-wrap .cont-table .phone input {padding:0 10px;line-height:28px;}
.shop-cont .nft-detail-wrap .cont-table .address {margin-right:12px;}
.shop-cont .nft-detail-wrap .cont-table .color-orange {color:#e45f35;}
.shop-cont .nft-detail-wrap .ico-check {display:block;margin-top:6px;padding-left:24px;background:url('./assets/images/bg_pop-checkmark.png') no-repeat;background-size:auto 20px;font-size:12px;line-height:20px;font-weight:normal;color:#7a928c;}

.shop-cont .bg-rainbow {background-image:linear-gradient(104deg, #ff6b6b 5%, #ffc877 24%, #fff58a 40%, #acff8e 61%, #95c5ff 79%, #9081ff 99%);}
.shop-cont .bg-black {background:#231f28;}
.shop-cont .bg-yellow {background:#f4ff00;}
.shop-cont .bg-orange {background:#ffda24;}
.shop-cont .bg-white {background:#fbfbfb;}
.shop-cont .bg-deep-red {background:#e8805f;}
.shop-cont .bg-green {background:#548c7e;}
.shop-cont .bg-purple {background:#9c7ccc;}







/* popup  */
.popup-wrap, .popup-wrap .dim {display:none;position:absolute;top:0;left:0;width:100%;height:100%;z-index:999;overflow-y:scroll;font-family:'Spoqa Han Sans Neo';}
.popup-wrap .dim {background:rgba(10, 20, 28, 0.5);z-index:0;}
.popup-wrap > div:not(.dim) {position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);}

.pop-type01 {border-radius:32px;background:#fff;box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.1);-webkit-box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.1);-ms-box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.1);}

.pop-connect {padding:30px 26px 20px;}
.pop-connect strong {font-family:'Josefin Sans';font-size:16px;font-weight:500;color:#0a141c;}
.pop-connect p {margin-top:7px;opacity:0.3;font-size:12px;color:#0a141c;}
.pop-connect .meta-mask {display:block;margin-top:17px;padding:0 15px;font-family:'Josefin Sans';border-radius:12px;border:solid 2px #e0e4e0;background:#fff;}
.pop-connect .meta-mask span {display:inline-block;padding-left:35px;background:url('./assets/images/ico_metamask.png') 0 50% no-repeat;background-size:auto 25px;font-family:'Josefin Sans';font-size:16px;font-weight:600;line-height:54px;color:#23262f;}
.pop-connect .meta-mask:hover,
.pop-connect .meta-mask:focus {border-color:#a0b7b1;}
.pop-connect .btn-round {width:220px;margin-top:16px;font-family:'Josefin Sans';line-height:44px;font-size:14px;font-weight:500;color:#fff;}

.connect-error {width:272px;padding:24px 0 20px;text-align:center;}
.connect-error .ico-error {padding-top:48px;background:url('./assets/images/bg_pop-error.png') 50% 0 no-repeat;background-size:auto 32px;font-size:14px;color:rgba(10, 20, 28, .5);}
.connect-error .btn-round {width:208px;margin:20px auto 0;border-radius:12px;background:#7a928c;font-family:'Josefin Sans';font-size:14px;font-weight:500;line-height:44px;color:#fff;}
.connect-error .pop-close {position:absolute;top:-35px;right:0;display:inline-block;padding-right:20px;background:url('./assets/images/btn_pop-close.png') 100% 50% no-repeat;background-size:auto 12px;font-family:'Josefin Sans';font-size:14px;font-weight:500;line-height:12px;color:#e0e4e0;}
.connect-error.width02 {width:362px;}

.pop-notice {padding:26px 26px 40px;width:520px;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}
.pop-notice .ico-notice {display:inline-block;padding-left:64px;background:url('./assets/images/bg_pop-alert.png') no-repeat;background-size:auto 48px;font-family:'Spoqa Han Sans Neo';font-size:20px;font-weight:500;line-height:48px;color:#0a141c;}
.pop-notice p {margin-top:16px;font-size:14px;color:rgba(10, 20, 28, .5);font-family:'Spoqa Han Sans Neo';}
.pop-notice .input {position:relative;display:block;margin-top:12px;padding:14px 12px;border:solid 2px #e0e4e0;background:rgba(255, 255, 255, 0.5);}
.pop-notice .input input {width:100%;border:none;padding:0;font-size:14px;line-height:28px;color:#474d46;font-family:'Josefin Sans';}
.pop-notice .txt-notice {position:absolute;margin-top:0;top:66px;left:0;font-family:'Spoqa Han Sans Neo';font-size:12px;color:#e45f35;}
.pop-notice .btn-line {width:214px;margin:40px auto 0;opacity:0.3;border:solid 2px #474d46;background:rgba(224, 228, 224, 0.3);color:#474d46;}
.pop-notice .pop-close {position:absolute;top:-35px;right:0;display:inline-block;padding-right:20px;background:url('./assets/images/btn_pop-close.png') 100% 50% no-repeat;background-size:auto 12px;font-family:'Josefin Sans';font-size:14px;font-weight:500;line-height:12px;color:#e0e4e0;}

.pop-notice-finish {padding:20px 0;width:328px;text-align:center;}
.pop-notice-finish p {padding-top:52px;background:url('./assets/images/bg_pop-checkmark.png') 50% 0 no-repeat;background-size:auto 40px;font-size:14px;color:rgba(10, 20, 28, .5);}
.pop-notice-finish .btn-round {width:208px;margin:20px auto 0;line-height:44px;}

.pop-gift {width:468px;padding:26px 26px 32px;}
.pop-gift .pop-close {position:absolute;top:-35px;right:0;display:inline-block;padding-right:20px;background:url('./assets/images/btn_pop-close.png') 100% 50% no-repeat;background-size:auto 12px;font-family:'Josefin Sans';font-size:14px;font-weight:500;line-height:12px;color:#e0e4e0;}
.pop-gift .ico-gift {display:inline-block;padding-left:64px;background:url('./assets/images/bg_pop-gift.png') no-repeat;background-size:auto 48px;font-family:'Spoqa Han Sans Neo';font-size:20px;font-weight:500;line-height:48px;color:#0a141c;}
.pop-gift p {margin-top:16px;font-size:14px;color:rgba(10, 20, 28, .5);font-family:'Spoqa Han Sans Neo';}
.pop-gift .input {position:relative;display:block;margin-top:12px;padding:14px 12px;border:solid 2px #e0e4e0;background:rgba(255, 255, 255, 0.5);}
.pop-gift .input input {width:100%;border:none;padding:0;font-size:14px;line-height:28px;color:#474d46;font-family:'Josefin Sans';}
.pop-gift .txt-notice {position:absolute;margin-top:0;top:70px;left:0;font-size:12px;color:#e45f35;}
.pop-gift .check-box {position:relative;margin-top:40px;padding:20px 50px 0 40px;border-top:1px solid #e0e4e0;}
.pop-gift .check-box input[type="checkbox"] + label {display:block;font-size:12px;color:#7a928c;cursor:pointer;text-indent:-28px;padding-left:28px;}
.pop-gift .check-box input[type="checkbox"] + label span {position:relative;margin-right:8px;display:inline-block;vertical-align:top;background:url('./assets/images/bg_check.png') no-repeat;background-size:auto 20px;width:20px;height:20px;}
.pop-gift .check-box input[type="checkbox"]:checked + label span {background:url('./assets/images/bg_check-on.png') no-repeat;background-size:auto 20px;}
.pop-gift .btn-line {width:214px;margin:20px auto 0;opacity:0.3;border:solid 2px #474d46;background:rgba(224, 228, 224, 0.3);color:#474d46;}
.pop-gift .btn-line.on {opacity:1;}

.pop-gift-finish {padding:48px 26px 32px;}
.pop-gift-finish .pop-close {position:absolute;top:-35px;right:0;display:inline-block;padding-right:20px;background:url('./assets/images/btn_pop-close.png') 100% 50% no-repeat;background-size:auto 12px;font-family:'Josefin Sans';font-size:14px;font-weight:500;line-height:12px;color:#e0e4e0;}
.pop-gift-finish > p {font-size:18px;font-weight:500;color:#7a928c;text-align:center;}
.pop-gift-finish figure {margin-top:16px;text-align:center;}
.pop-gift-finish .info {margin-top:16px;font-family:'Josefin Sans';}
.pop-gift-finish .info span {display:block;font-size:14px;color:rgba(10, 20, 28, .5);}
.pop-gift-finish .info div {width:308px;margin-top:6px;padding:14px 12px;border:solid 2px #e0e4e0;background:rgba(224, 228, 224, 0.3);box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;font-size:14px;color:#474d46;word-break:break-all;}
.pop-gift-finish .info .btn-transaction {display:block;margin-top:19px;font-size:14px;font-weight:600;line-height:1.86;text-align:center;color:#7a928c;text-decoration:underline;}

.pop-exchange {width:360px;padding:36px 0 32px;text-align:center;}
.pop-exchange .pop-close {position:absolute;top:-35px;right:0;display:inline-block;padding-right:20px;background:url('./assets/images/btn_pop-close.png') 100% 50% no-repeat;background-size:auto 12px;font-family:'Josefin Sans';font-size:14px;font-weight:500;line-height:12px;color:#e0e4e0;}
.pop-exchange > strong {display:block;font-size:18px;font-weight:500;color:#7a928c;}
.pop-exchange > p {margin-top:8px;font-size:14px;color:rgba(10, 25, 28, .5);}
.pop-exchange figure {margin-top:24px;}
.pop-exchange figure img {height:120px;}
.pop-exchange .btn-exchange {display:block;margin-top:16px;font-size:14px;line-height:1.86;color:#7a928c;text-decoration:underline;}





/* footer */
footer {text-align:center;background:#7a928c;}
footer .inner-box {position:relative;max-width:1440px;margin:0 auto;padding:80px 0;}
footer strong {display:block;font-family:'Josefin Sans';font-size:24px;font-weight:500;line-height:1.67;color:#fff;}
footer ul {margin-top:32px;font-size:0;}
footer li {display:inline-block;vertical-align:middle;margin:0 28px;}
footer li a {display:block;}
footer li img {height:60px;}
footer .copy {font-family:'Josefin Sans';font-size:14px;line-height:90px;color:#474d46;background:#fff;}

.sub-page footer {background:#fbfbfb;/*position:absolute;bottom:0;left:0;width:100%;*/}
.sub-page footer .copy {border-top:1px solid #7a928c;}
.papa-membership footer {background:#a0b7b1;}
.papa-membership footer .inner-box {padding:100px 0;}
.papa-membership footer span {display:block;font-size:16px;line-height:1.75;text-align:center;color:#fbfbfb;}
.papa-membership footer ul {margin-top:48px;}
.papa-membership footer li img {height:100px;}

/* mobile web */

@media (max-width: 1280px) {
    #contents {max-width:375px;margin:0 auto;}
    html.nav-open {overflow-y:hidden;}
    .pc {display:none;}
    .mobile {display:block !important;}

    .header-inner {padding:38px 20px;}
    .header-inner h1 {float:none;background-size:auto 20px;width:120px;height:38px;}
    .header-inner > div {display:none;}
    .btn-menu {display:block;position:absolute;top:41px;right:20px;width:32px;height:32px;z-index:9999;background:url('./assets/images/btn_menu.png') no-repeat;background-size:auto 32px;text-indent:-9999px;}
    .sub-page .btn-menu {background-image:url('./assets/images/btn_menu-w.png');}
    .mobile-nav {overflow-y:scroll;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;display:block;padding:160px 64px 64px;position:fixed;top:0;right:-100%;/*right:0;*/height:100%;width:100%;background:#fff url('./assets/images/bg_mobile-nav.jpg') no-repeat;background-size:cover;z-index:99999;}
    .mobile-nav .close {position:absolute;top:41px;right:20px;}
    .mobile-nav .close img {height:32px;}
    .mobile-nav .select-box {position:absolute;top:45px;left:40px;margin:0;}
    .mobile-nav .select-box button {line-height:24px;color:#fff;}
    .mobile-nav .select-box button:after {background-image: url(./assets/images/ico_dropdown-w.png);}
    .mobile-nav .gnb li:first-child {margin-top:0;}
    .mobile-nav .gnb li {position:relative;margin-top:32px;}
    .mobile-nav .gnb li a {position:relative;font-family:'Josefin Sans';font-size:32px;line-height:1.25;letter-spacing:1px;color:#fff;}
    .mobile-nav .gnb li a:after {display:none;content:'';position:absolute;top:50%;margin-top:-1px;left:-24px;width:12px;height:2px;background:#fff;border-radius:1px;}
    .mobile-nav .gnb li.on a:after {display:block;}
    .mobile-nav .btn-line {width:auto;margin-bottom:50px;border:2px solid #fff;background:rgba(224, 228, 224, 0.1);font-family:'Josefin Sans';font-size:12px;line-height:52px;text-align:center;letter-spacing:1px;text-transform:uppercase;color:#fff;}
    .mobile-nav .select-box button:before {content:'';position:absolute;top:0;bottom:0;width:100%;height:50px;}
    .mobile-nav .select-box > div {top:32px;left:-16px;}

    .white-ver .header-inner {padding:45px 30px 11px;}
    .white-ver .header-inner h1 {height:24px;}
    .white-ver + .btn-menu {background-image:url('./assets/images/btn_menu-c.png');}

    .tit {font-size:28px;}
    .mobile-br {display:block;}

    .select-box {margin-top:40px;margin-left:0;}
    .select-box button {padding-left:0;line-height:50px;}
    /*.select-box button:before {display:none;}*/
    .select-box > div {display:none;width:auto;top:50px;left:0;}
    .select-box > div ul li a {display:block;padding:0 38px 0 16px;font-size:16px;line-height:44px;}

    .connected {margin-left:0;position:absolute;top:112px;left:40px;}
    .network-error {/*top:56px;*/right:auto;margin:0 16px;padding:14px 16px 14px 56px;font-size:13px;line-height:normal;background-position:16px 14px;}

    /* content */
    .kv {background:url('./assets/images/bg_kv-m.jpg') 0 50% no-repeat;background-size:cover;height:auto;}
    .kv .text {padding:168px 32px 64px;}
    .kv .text span {font-size:20px;line-height:1.2;}
    .kv .text strong {font-size:56px;line-height:1.14;letter-spacing:2px;}
    .kv .text strong + p {margin-top:116px;}
    .kv .text p {width:auto;margin-top:32px;font-size:14px;line-height:1.71;word-break:break-all;}

    .feature01 {padding:120px 0 100px;}
    .feature01 .illust {display:none;}
    .feature01 .desc {padding:0 55px;}
    .feature01 .product-wrap {margin-top:32px;}
    .feature01 .product-wrap .bx-wrapper {width:100%;}
    .feature01 .product-wrap .bx-wrapper .bx-pager.bx-default-pager a {width:5px;height:5px;margin:0 3px;background:#7a928c;opacity:.3;}
    .feature01 .product-wrap .bx-wrapper .bx-pager.bx-default-pager a.active,
    .feature01 .product-wrap .bx-wrapper .bx-pager.bx-default-pager a:focus,
    .feature01 .product-wrap .bx-wrapper .bx-pager.bx-default-pager a:hover {opacity:1;}
    .feature01 .product-wrap ul li {width:280px !important;margin:0 0 0 32px;}
    .feature01 .product-wrap ul li:first-child {margin-left:0;}
    .feature01 .product-wrap figure {width:280px;height:280px;}
    .feature01 .product-wrap .info strong {width:280px;font-size:18px;line-height:1.44;}
    .feature01 .slider-w {display:none;}
    .feature01 .slider-m {display:block;width:100%;}

    .feature02 {position:relative;padding-bottom:100px;background:initial;}
    .feature02 figure {overflow:hidden;height:300px;}
    .feature02 figure img {width:100%;}
    .feature02 .flex {display:block;background:#fbfbfb;}
    .feature02 .tit {position:absolute;top:100px;left:50%;width:310px;margin-left:-155px;padding-bottom:20px;font-size:28px;line-height:1.44;color:#fff;letter-spacing:normal;}
    .feature02 .tit:after {width:50px;margin-left:-25px;background:#fff;}
    .feature02 .text {padding:0 32px;}
    .feature02 .desc {color:#474d46;}
    .feature02 .text p {margin-top:30px;}
    .feature02 .text p:first-child {margin-top:48px;}
    .feature02 .text strong {width:220px;margin:56px auto 0;color:#474d46;}
    .feature02 .btn-line {margin-top:32px;width:307px;color:#474d46;border-color:#474d46;}

    .feature03 {padding:120px 0 100px;}
    .feature03 > strong {font-size:28px;line-height:1.43;}
    .feature03 .flex {display:block;margin-top:40px;padding:0 32px;}
    .feature03 .flex figure {margin-right:0;text-align:center;}
    .feature03 .flex figure img {height:232px;max-width:none;}
    .feature03 .text {margin-top:64px;}
    .feature03 .text .tit {padding-bottom:20px;font-size:20px;letter-spacing:normal;line-height:1.3;}
    .feature03 .text .tit:after {width:50px;}
    .feature03 .text .tit + p {margin-top:20px;}

    .feature04 {padding:120px 0 100px;}
    .feature04 .membership {margin-top:56px;padding:0 32px;}
    .feature04 .membership > div {display:block;margin:40px auto 0;}
    .feature04 .membership ul {background:transparent;box-shadow:none;}

    .feature04 .membership strong {display:none;}
    .feature04 > p {width:240px;margin:48px auto 0;font-size:18px;line-height:1.78;}
    .feature04 > figure {width:280px;margin:48px auto 0;}
    .feature04 > figure img {height:280px;}
    .feature04 .illust {top:30px;right:-31px;}
    .feature04 .btn-line {margin:48px auto 0;width:307px;}
    .feature04 .membership .mem-tit {display:block;}

    /* about */
    .about-kv {height:auto !important;}
    .about-kv .text {word-break:keep-all;position:relative;top:auto;padding:168px 32px 308px;box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box;box-sizing:border-box;}
    .about-kv .text span {font-size:20px;line-height:1.20;}
    .about-kv .text strong {font-size:56px;line-height:1.14;}
    .about-kv.kv01 {background-image:url('./assets/images/img_about-m.jpg');}
    .about-kv.kv02 {background-image:url('./assets/images/img_about-abg-m.jpg');}
    .about-kv.kv02 .text {top:auto;padding:160px 32px 283px;}
    .about-kv.kv02 .text .tit {font-size:60px;line-height:1.07;letter-spacing:4px;}
    .about-kv.kv02 .text strong {margin-top:33px;font-size:24px;line-height:1.67;}

    .effect-box {top:-165px;}
    .effect-box:before,
    .effect-box:after {width:330px;margin-left:-165px;}
    .effect-box:before {top:0;height:300px;border-radius:4px;box-shadow:0 2px 27px 0 rgba(0, 0, 0, 0.4);-webkit-box-shadow:0 2px 27px 0 rgba(0, 0, 0, 0.4);-ms-box-shadow:0 2px 27px 0 rgba(0, 0, 0, 0.4);background:#fbfbfb;}
    .effect-box:after {background:linear-gradient(0deg, rgba(251,251,251,1) 46%, rgba(251,251,251,0) 100%);width:1168px;height:160px;margin-left:-584px;bottom:0;}

    .about01 {padding-bottom:100px;}
    .about01 .tit {top:-118px;margin-bottom:-118px;font-size:20px;}
    .about01 .desc {width:280px;}
    .about01 .desc p {margin-top:28px;word-break:keep-all;}
    .about01 .desc p:first-child {margin-top:20px;}
    .about01 .desc .br {display:inline;}
    .about01 .about01-1 figure {margin-top:28px;}
    .about01 .about01-1 figure img {height:206px;}
    .about01 .about01-1 figcaption {width:280px;word-break:keep-all;margin:28px auto 0;}
    .about01 .about01-1 .desc {width:auto;padding:0 32px;}
    .about01 .about01-1 .desc p,
    .about01 .about01-1 .desc strong {margin-top:28px;}
    .about01 .about01-1 .desc strong {width:230px;margin:28px auto 0;}
    .about01 .about01-2 figure {margin-top:-15px;}
    .about01 .about01-2 .desc {margin-top:-11px;width:auto;padding:0 32px;word-break:keep-all;}
    .about01 .about01-2 .btn-line {margin-top:32px;width:307px;}

    .about02 {padding-bottom:100px;}
    .about02 .effect-box {top:-206px;}
    .about02 .effect-box:before,
    .about02 .effect-box:after {width:330px;margin-left:-165px;}
    .about02 figure {top:-200px;margin-bottom:-200px;}
    .about02 figure img {margin-top:10px;}
    .about02 .desc {width:auto;margin:40px auto 0;padding:0 48px;}
    .about02 .desc p {margin-top:28px;}
    .about02 .btn-line {width:307px;}

    .ground-kv {height:1026px;background-image:url('./assets/images/img_ground-m.jpg');}
    .ground-kv .text {top:354px;right:auto;width:auto;margin:0 32px;padding:40px 24px;}
    .ground-kv .text strong {font-size:28px;line-height:1.43;}
    .ground-kv .text p {font-size:14px;line-height:1.71;}
    .ground-kv .text span {margin:32px -24px 0;font-size:16px;line-height:1.5;}
    .ground-kv .text .btn-line {position:absolute;bottom:-88px;left:50%;margin:32px auto 0 -85px;}

    .ground01 {padding:80px 0;}
    .ground01 .tit {width:260px;margin:0 auto;}
    .ground01 .desc {margin-top:48px;padding:0 32px;}
    .ground01 .ground-slide {margin-top:48px;width:320px;margin-left:-160px;}
    .ground01 .ground-slide li {margin:0 5px;padding:0 5px;border-radius:12px;}
    .ground01 .ground-slide img {border-radius:12px;}

    .ground02 {padding:80px 32px;}
    .ground02 .inner-box {display:block;}
    .ground02 figure {flex:none;margin-right:0;position:absolute;top:126px;}
    .ground02 figure img {height:312px;}
    .ground02 h2 + .desc {margin-top:388px;}
    .ground02 .desc {margin-top:28px;width:auto;}

    .ground03 {padding:80px 32px;}
    .ground03 .inner-box {flex-direction:column-reverse;}
    .ground03 figure {flex:none;}
    .ground03 .text {margin-top:40px;}
    .ground03 br {display:none;}

    .ground04 {padding:80px 0;}
    .ground04 strong {font-size:28px;line-height:1.43;letter-spacing:0.88px;}
    .ground04 .btn-line {margin:32px auto 0;}

    /* membership */
    .papa-membership .kv .text {padding:168px 32px 64px;}
    .papa-membership .kv .text p {width:auto;}
    .papa-membership .kv figure {right:auto;top:408px;left:32px;width:auto;}
    .papa-membership .kv figure img {height:311px;}
    .papa-membership .kv .text strong {margin-top:32px;font-size:56px;line-height:1.14;letter-spacing:2px;}
    .papa-membership .kv .text strong + p {margin-top:592px;}
    .papa-membership .kv .count-box {
        margin-top:0;width:311px;position:absolute;top:751px;left:32px;
        font-size:24px;letter-spacing:1.5px;
    }
    .papa-membership .kv .btn-box {margin-top:0;position:absolute;top:855px;left:32px;}

    .papa-membership .membership01 {padding:80px 0;}
    .papa-membership .membership01 .inner-box {padding-top:108px;background:url('./assets/images/bg_effect-story-m.png') no-repeat;}
    .papa-membership .membership01 .desc {padding:0 48px;word-break:keep-all;}
    .papa-membership .membership01 .desc br {display:none;}

    .papa-membership .membership02 {padding:120px 0;}
    .papa-membership .membership02 .slide-m {display:block;}
    .papa-membership .membership02 .slide-w {display:none;}
    .papa-membership .membership02 ul {overflow:hidden;}
    .papa-membership .membership02 ul li {width:auto !important;margin:0 0 0 15px;}

    .papa-membership .membership03 {padding:80px 0 120px;}
    .papa-membership .membership03 > .desc {padding:0 32px;word-break:keep-all;width:255px;margin:24px auto 0;}
    .papa-membership .membership03 > .desc br {display:none;}
    .papa-membership .membership03 .type-list {width:auto;margin:0 auto;}
    .papa-membership .membership03 .type-list > li {width:311px !important;min-height:736px;margin:0 8px 0;}
    .papa-membership .membership03 .type-list figure {height:309px;}
    .papa-membership .membership03 .slide-wrap {margin-top:48px;padding-left:24px;}
    .papa-membership .membership03 .bx-wrapper {box-shadow:none;border:none;background:none;margin:0;}
    .papa-membership .membership03 .bx-wrapper .bx-pager {display:block;}
    .papa-membership .membership03 .bx-wrapper .bx-pager.bx-default-pager a {width:5px;height:5px;background:rgba(122, 146, 140, .3);margin:0 3px;}
    .papa-membership .membership03 .bx-wrapper .bx-pager.bx-default-pager a.active,
    .papa-membership .membership03 .bx-wrapper .bx-pager.bx-default-pager a:focus,
    .papa-membership .membership03 .bx-wrapper .bx-pager.bx-default-pager a:hover {background:#7a928c;}

    .papa-membership .membership04 {padding:80px 0;}
    .papa-membership .membership04 .illust {bottom:auto;top:23px;}
    .papa-membership .membership04 .desc {padding:0 32px;}
    .papa-membership .membership04 .desc br {display:none;}
    .papa-membership .membership04 .benefit {margin-top:0;}
    .papa-membership .membership04 .benefit > div {margin:48px 0 0;}

    .papa-membership .membership06 {padding:80px 0;}
    .papa-membership .membership06 .qna-list {width:auto;margin:24px auto 0;padding:0 32px;}
    .papa-membership .membership06 .qna-list li {padding:24px 0;}
    .papa-membership .membership06 .qna-list .question a {font-size:16px;line-height:1.5;padding-left:53px;text-indent:-53px;}
    .papa-membership .membership06 .qna-list .question a:after {display:none;}
    .papa-membership .membership06 .qna-list .question a em {margin-right:20px;font-size:28px;}
    .papa-membership .membership06 .qna-list .answer {display:none;padding:32px 0 32px 53px;}
    .papa-membership .membership06 .qna-list li.on .question a:after {}

    /* shop */
    .shop-cont {padding:35px 32px 60px;}
    .shop-cont .user-info {position:relative;}
    .shop-cont .user-info .pic {flex:54px 0 0;}
    .shop-cont .user-info .text {padding-bottom:80px;}
    .shop-cont .user-info .text > strong {font-size:16px;line-height:24px;}
    .shop-cont .user-info .text .btn-edit {margin-top:0;}
    .shop-cont .user-info .text > span {margin-top:6px;}
    .shop-cont .user-info .btn {margin-top:0;position:absolute;bottom:0;left:0;}
    .shop-cont .user-info.edit .edit-box .btn-small {display:block;margin-left:0;margin-top:8px;}
    .shop-cont .user-info.edit .edit-box .btn-small a:first-child {margin-left:0;}
    .shop-cont .user-info.edit .text .notice {width:254px;margin-top:6px;}
    .shop-cont .user-info.edit .text > span {margin-top:6px;}
    .shop-cont .notice-box {position:relative;top:auto;right:auto;margin-top:24px;padding:14px 16px 15px;}
    .shop-cont .notice-box p {margin-top:12px;}


    .shop-cont .tab {margin-top:25px;}
    .shop-cont .cont-box.empty {padding-top:45px;}

    .shop-cont .btn-product-info {position:absolute;top:16px;right:20px;margin-top:0;font-size:14px;}
    .shop-cont .btn-product-info span {padding-left:19px;background-size:auto 16px;}

    .shop-cont .membership-list > li {display:block;width:auto;margin:24px 0 0 0;}
    .shop-cont .membership-list > li:nth-child(-n+4) {margin-top:24px;}
    .shop-cont .membership-list .text {padding:16px 20px;}
    .shop-cont .membership-list .text .list-tit {font-size:18px;}
    .shop-cont .membership-list .text .btn-detail {font-size:14px;line-height:48px;}

    .shop-cont .nft-list > li {display:block;width:auto;margin:24px 0 0 0;}
    .shop-cont .nft-list > li:nth-child(-n+3) {margin-top:24px;}
    .shop-cont .nft-list .text {padding:16px 20px;}
    .shop-cont .nft-list .text .list-tit {margin-top:8px;font-size:18px;}
    .shop-cont .nft-list .text > em {font-size:14px;}
    .shop-cont .nft-list .text .btn-box {padding:16px 0 0;border-bottom:none;}
    .shop-cont .nft-list .text .btn-box a {display:inline-block;margin:0 0 0 7px;padding:0 37px}
    .shop-cont .nft-list .text .btn-box a:first-child {margin-left:0;}
    .shop-cont .nft-list .text .btn-round {font-size:14px;line-height:48px;}
    .shop-cont .nft-list .text .btn-round.line {line-height:46px;}

    .shop-cont .back {position:relative;padding-left:40px;}
    .shop-cont .membership-list-wrap {margin-top:5px;}
    .shop-cont .membership-list-wrap .slide-wrap {width:311px;margin-top:40px;}
    .shop-cont .membership-list-wrap .slide-wrap .bx-wrapper .bx-controls-direction a {top:132px;width:16px;height:16px;}
    .shop-cont .membership-list-wrap .slide-wrap .bx-wrapper .bx-prev {left:-24px;background:url('./assets/images/bg_arrow-prev.png') no-repeat;background-size:auto 16px;}
    .shop-cont .membership-list-wrap .slide-wrap .bx-wrapper .bx-next {right:-24px;background:url('./assets/images/bg_arrow-next.png') no-repeat;background-size:auto 16px;}
    .shop-cont .membership-list-wrap .type-list .text {padding:31px 24px 20px;}
    .shop-cont .membership-list-wrap .usage-history {margin-top:56px;}
    .shop-cont .membership-list-wrap .usage-history .desc {font-size:14px;}
    .shop-cont .membership-list-wrap .usage-history .desc .br {display:inline;}
    .shop-cont .membership-list-wrap .tab {margin-top:50px;}
    .shop-cont .membership-list-wrap .cont li {display:block;margin:20px auto 0;}
    .shop-cont .membership-list-wrap .cont li:first-child {margin-top:24px;}
    .shop-cont .membership-list-wrap .cont li:nth-child(4n+1) {margin-left:auto;}
    .shop-cont .membership-list-wrap .cont .desc {text-align:center;}
    .shop-cont .membership-list-wrap .cont .btn {text-align:center;}


    /* popup */
    .pop-notice {width:375px;min-height:700px;transform:translate(-50%, 0) !important;top:112px !important;border-bottom-left-radius:0;border-bottom-right-radius:0;padding:32px 24px 40px;}
    .pop-notice p {margin-top:32px;font-size:16px;}
    .pop-notice .input {padding:12px;}
    .pop-notice .input input {width:100%;border:none;padding:0;font-size:14px;line-height:28px;color:#474d46;font-family:'Josefin Sans';}
    .pop-notice .txt-notice {font-size:13px;}
    .pop-notice .btn-line {width:auto;margin:50px auto 0;}
    .pop-notice .pop-close {top:-25px;right:25px;}

    .pop-notice-finish {width:275px;padding:20px;}
    .pop-notice-finish p {padding-top:56px;font-size:16px;width:230px;margin:0 auto;}
    .pop-notice-finish .btn-round {width:auto;line-height:56px;}

    /* footer */
    footer .inner-box {padding:80px 0;}
    footer li {margin:0 12px;}
    footer .copy {font-size:12px;line-height:65px;}
    .sub-page footer .copy {border-top:1px solid #7a928c;}



}

